import Box from "@mui/material/Box";

export default function CTDLogo() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "10px",
        left: "16px",
        height: "44px",
        width: "208px",
        zIndex: "10000",
      }}
    >
      <a href="https://iota-ml.com/membership-account/">
        <img
          src="iota-logo.png"
          style={{ width: "208px", height: "44px", objectFit: "contain" }}
          alt="logo"
        />
      </a>
    </Box>
  );
}
