import { Typography } from "@mui/material";
import RealStateProvider from "../wss/realState/realStateProvider";
import Status from "./../wss/components/status";

export default function Dashboard() {
  return (
    <>
      <Typography>Dashboard</Typography>
    </>
  );
}
