// api Functions

export default async function SendToApi(route, values) {
  let _headers = {
    "Content-Type": "application/json",
  };

  // add token and username
  values.token = localStorage.getItem("token");
  values.username = localStorage.getItem("username");

  console.log("token " + values.token);
  console.log("username " + values.username);

  var requestOptions = {
    method: "POST",
    headers: _headers,
    body: JSON.stringify(values),
  };

  try {
    //console.log(process.env.NODE_ENV);
    console.log(process.env.REACT_APP_API_URL + route);
    //console.log(requestOptions);
    const response = await fetch(
      process.env.REACT_APP_API_URL + route,
      requestOptions
    );
    const json = await response.json();
    return { response, json };
  } catch (err) {
    console.log(err.message);
    return false;
  }
}
export async function SendFormToApi(route, formData) {
  // formData must include username and token
  var requestOptions = {
    method: "POST",
    body: formData,
  };

  try {
    //console.log(process.env.REACT_APP_API_URL + route)
    const response = await fetch(
      process.env.REACT_APP_API_URL + route,
      requestOptions
    );
    const json = await response.json();
    return { response, json };
  } catch (err) {
    //console.log(err.message)
    return false;
  }
}
