import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Badge, IconButton, useTheme } from "@mui/material";
// dialogs
import SettingsDialog from "./dialogs/settingsdialog";
import AccountDialog from "./dialogs/accountdialog";
import AlertDialog from "./dialogs/alertdialog";
// icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useContext, useState } from "react";
import GlobalStateContext from "./../globalstate/globalStateContext";

const drawerWidth = 240;

export default function CTDAppBar() {
  const [settings, setSettings] = useState(false);
  const [account, setAccount] = useState(false);
  const [alert, setAlert] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const global = useContext(GlobalStateContext);

  const handleDialog = (setValue, value) => {
    setValue(value);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            background: "white",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                gap: "1rem",
              }}
            >
              <img
                src="iota-predict-logo.png"
                style={{ width: "44px", height: "44px", objectFit: "contain" }}
                alt="logo"
              />
              <Typography variant="h6" component="div" color="primary">
                iota-PREDICT
              </Typography>
            </Box>
            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setAlert, true)}
            >
              <Badge badgeContent={27} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setAccount, true)}
            >
              <AccountCircleIcon
                color={global.userInfo.loggedIn ? "success" : "error"}
              />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setSettings, true)}
            >
              <SettingsIcon
                color={
                  !global.state.connectionError && global.state.wssConnected
                    ? "success"
                    : "error"
                }
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={settings}
        onClose={() => handleDialog(setSettings, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <SettingsDialog handleClose={() => handleDialog(setSettings, false)} />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={account}
        onClose={() => handleDialog(setAccount, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <AccountDialog handleClose={() => handleDialog(setAccount, false)} />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={alert}
        onClose={() => handleDialog(setAlert, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <AlertDialog handleClose={() => handleDialog(setAlert, false)} />
      </Dialog>
    </div>
  );
}
