import { Box } from "@mui/system";

const Row = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#aabbcc",
          width: "100%",
          height: "100px",
          display: "flex",
          gap: "1rem",
          // row
          //justifyContent: "flex-end",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#121212",
            width: "50px",
            height: "50px",
            display: "flex",
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: "#121212",
            width: "50px",
            height: "50px",
            display: "flex",
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: "#121212",
            width: "50px",
            height: "50px",
            display: "flex",
          }}
        ></Box>
      </Box>
    </>
  );
};
const Column = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#aabbcc",
          width: "100%",
          // removed height as now column
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          // Column
          //alignItems: "flex-end",
          alignItems: "stretch",
          //   alignItems: "center",
          alignContent: "stretch",
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            height: "50px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box>A</Box>
          <Box>B</Box>
          <Box>C</Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#ffffff",
            height: "50px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <Box sx={{ flexGrow: "1" }}>A</Box>
          <Box>B</Box>
          <Box>C</Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#ffffff",

            height: "50px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Box sx={{ border: "solid thin red", width: "50%" }}>1</Box>
          <Box sx={{ border: "solid thin red", width: "50%" }}>2</Box>
        </Box>
      </Box>
    </>
  );
};
export default function Scores() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Row />
        <Row />
        <Column />
      </Box>
    </>
  );
}
