import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StateProvider from "./globalstate/globalStateProvider";
import { BrowserRouter } from "react-router-dom";

import { createTheme, CssBaseline } from "@mui/material";
import { deepPurple, green, purple, red, blue } from "@mui/material/colors";
import { ThemeProvider } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: purple,
    secondary: blue,
  },
});

console.log(process.env);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StateProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
