import MenuItem from "./menuitem.js";
import { Divider, List } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

// Icons
import StorageIcon from "@mui/icons-material/Storage";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import IconDashboard from "@mui/icons-material/Dashboard";
import IconBarChart from "@mui/icons-material/BarChart";

const menuItems = [
  {
    name: "Dashboard",
    link: "/",
    Icon: IconDashboard,
  },
  {
    name: "Storage",
    link: "/storage",
    Icon: StorageIcon,
  },
  {
    name: "Jobs",
    Icon: LocalOfferIcon,
    items: [
      {
        name: "New",
        link: "/newjob",
      },
    ],
  },

  {
    name: "Reports",
    link: "/reports",
    Icon: IconBarChart,
  },
  {
    name: "Scores",
    link: "/scores",
    Icon: FormatListNumberedIcon,
  },
  /*{
    name: "Nested Pages",
    Icon: IconLibraryBooks,
    items: [
      {
        name: "Level 1",
        link: "/nested1",
      },
      {
        name: "Level 2",
        items: [
          {
            name: "Level 3",
          },
          {
            name: "Level 3",
          },
        ],
      },
    ],
  },*/
];

export default function Menu() {
  const [selected, setSelected] = useState(0);

  function handleClick(index) {
    console.log(index + " menu selected");
    setSelected(index);
  }

  return (
    <div>
      <Box sx={{ width: "240px", height: "64px" }} />
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <MenuItem
            {...item}
            key={index}
            selectedKey={index}
            onSelect={handleClick}
            selected={selected}
          />
        ))}
      </List>
    </div>
  );
}
