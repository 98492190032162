import { Switch, Route } from "react-router-dom";

import CTDAppBar from "./appbar/appbar";
import CTDLogo from "./logo/logo";

import Drawer from "@mui/material/Drawer";

import Menu from "./menu/menu";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";

import { Container } from "@mui/material";
// content
import Dashboard from "./content/dashboard";
import NewJob from "./content/newjob";
import Scores from "./content/scores";

export default function App() {
  const theme = useTheme();

  return (
    <>
      <CTDAppBar />
      <CTDLogo />
      <Drawer
        sx={{
          width: "240px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Menu />
      </Drawer>

      <Box
        sx={{
          marginTop: "64px",
          marginLeft: "240px",
          padding: theme.spacing(2),
        }}
      >
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/newjob" exact component={NewJob} />
          <Route path="/scores" exact component={Scores} />
        </Switch>
      </Box>
    </>
  );
}
