import { useContext, useEffect, useState } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";
import RealStateContext from "./realStateContext";
const wss = new WebSocket("wss://wss.codetechs-ws.co.uk");

//const wss = new WebSocket('wss://wss.codetechs-ws.co.uk');

const RealStateProvider = (props) => {
  const [realTime, setRealTime] = useState({});
  const [wssChannels, setWssChannels] = useState([]);

  const global = useContext(GlobalStateContext);

  function sendWssMessage(msg) {
    msg.source = "wp";
    msg.api_access_token = localStorage.getItem("token");
    msg.user_id = localStorage.getItem("userId");
    msg = JSON.stringify(msg);
    wss.send(msg);
  }

  function addWssChannel(channel) {
    console.log("adding new channel");
    channel.action = "join";
    sendWssMessage(channel);
    setWssChannels((channels) => [...channels, channel]);
  }

  useEffect(() => {
    console.log("in realtime provider");
    wss.onopen = () => {
      console.log("wss connected");
      global.setState((prevState) => ({
        ...prevState,
        wssConnected: true,
      }));
    };
    wss.onclose = () => {
      console.log("wss closed");
      global.setState((prevState) => ({
        ...prevState,
        wssConnected: false,
      }));
    };

    return () => {
      console.log("closing wss");
      wss.close();
    };
  }, []);

  useEffect(() => {
    console.log("current realtime");
    console.log(realTime);
    wss.onmessage = (msg) => {
      console.debug("ok then wss message rx:", msg.data);
      try {
        var decoded = JSON.parse(msg.data);
      } catch (e) {
        return;
      }
      console.log("here is the decoded msg");
      setRealTime({ ...realTime, [decoded.job_id]: decoded });
    };
  }, [realTime]);

  return (
    <RealStateContext.Provider
      value={{
        realTime: realTime,
        addWssChannel: addWssChannel,
      }}
    >
      {props.children}
    </RealStateContext.Provider>
  );
};

export default RealStateProvider;
