import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

import { DoneIcon } from "@mui/icons-material/Done";

import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import FileUpload from "../components/fileupload/fileupload";
import SendToApi from "./../api/sendtoapi";
import GlobalStateContext from "./../globalstate/globalStateContext";
import clsx from "clsx";
import { Box } from "@mui/system";
import { styled } from "@mui/system";
import SuccessAvatar from "../components/successavatar";
import ApiButton from "../components/apibutton";
import UI from "../data/clients.json";
import { useEffect } from "react";
import { Fragment } from "react";
import { useParams } from "react-router";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function NewJob() {
  //const params = useParams();
  //const client = params.client;
  const [uiClientdata, setUiClientdata] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const client = queryParams.get("client");

  const _uiClientdata = UI.clients[client];

  useEffect(() => {
    if (client) setUiClientdata(_uiClientdata);
  }, []);

  const [state, setState] = useState({
    one: true,
    two: false,
    three: false,
  });

  function GroupRadio(props) {
    const { group } = props;

    return (
      <RadioGroup
        aria-label={group}
        defaultValue="one"
        name="radio-buttons-group"
      >
        {group.items.map((item, i) => {
          console.log("value");
          console.log(String(i + 1));
          return (
            <FormControlLabel
              labelPlacement="start"
              value={String(i + 1)}
              control={<Radio />}
              label={item.label}
            />
          );
        })}
      </RadioGroup>
    );
  }
  function GroupCheckbox(props) {
    const { group } = props;

    return (
      <FormGroup
        aria-label={group}
        defaultValue="one"
        name="radio-buttons-group"
      >
        {group.items.map((item, i) => {
          console.log("value");
          console.log(String(i + 1));
          return (
            <FormControlLabel
              labelPlacement="start"
              value={String(i + 1)}
              control={<Checkbox />}
              label={item.label}
            />
          );
        })}
      </FormGroup>
    );
  }
  function GroupSlider(props) {
    const { group } = props;

    return (
      <Slider sx={{ width: "100%" }} defaultValue={30} aria-label="slider" />
    );
  }

  function Group(props) {
    const { group } = props;
    console.log("group");
    console.log(group);
    switch (group.type) {
      case "radio":
        return <GroupRadio group={group} />;
      case "checkbox":
        return <GroupCheckbox group={group} />;

      case "slider":
        return <GroupSlider group={group} />;

      default:
        return <h1>Hi</h1>;
    }
  }

  return (
    <Fragment>
      {uiClientdata && (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {Object.keys(uiClientdata).map((column) => {
            return (
              <Paper>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    //width: "500px",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography sx={{ textTransform: "uppercase" }} variant="h7">
                    {column}
                  </Typography>
                  {uiClientdata[column].map((group) => {
                    return (
                      <Paper>
                        <Box
                          sx={{
                            minWidth: "180px",
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: "1",
                            padding: "1rem",
                          }}
                        >
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {Object.keys(group)}
                            </FormLabel>
                            <Group group={group[Object.keys(group)]} />
                          </FormControl>
                        </Box>
                      </Paper>
                    );
                  })}
                </Box>
              </Paper>
            );
          })}
          <Paper
            sx={{
              padding: "3px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div></div>
            <Button
              variant="contained"
              component="label"
              color="primary"
              size="medium"
              sx={{}}
            >
              Run
            </Button>
          </Paper>
        </Box>
      )}
    </Fragment>
  );
}
