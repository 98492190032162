import { Button } from "@mui/material";
import { keyframes, styled } from "@mui/system";

const bounce = keyframes`
0% { background-position: 0% 50%; }
50% { background-position: 100% 50%;}
100% {  background-position: 0% 50%; }
`;

const CustomButton = styled(Button)(({ theme }) => ({
  animation: `${bounce} 1s infinite`,
  background: "linear-gradient(270deg, #2A577C, #36A6CD)",
  backgroundSize: "400% 400%",
  color: "#FFFFFF !important",
}));

export default function ApiButton(props) {
  const { submitting } = props;

  return (
    <>
      {submitting == "true" && <CustomButton {...props} />}
      {submitting == "false" && <Button {...props} />}
    </>
  );
}
