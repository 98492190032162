import React, { useEffect, useState } from "react";
import SendToApi from "../api/sendtoapi";
import GlobalStateContext from "./globalStateContext";
import jwt_decode from "jwt-decode";
import { ConstructionOutlined } from "@mui/icons-material";

const GlobalStateProvider = (props) => {
  const [state, setState] = useState({
    validating: false,
    error: "",
    activeState: false,
    role: "",
    selectedRow: "",
    failedAuth: false,
    wssConnected: false,
    connectionError: false,
  });

  const [userInfo, setUserInfo] = useState({
    logo: false,
    token: null,
    username: null,
    loggedIn: false,
    userId: false,
    colour1: false,
    colour2: false,
    coloursDirty: false,
  });

  const [selectorValue, setSelectorValue] = useState({
    t1: false,
    t2: false,
    c1: false,
    logo: false,
  });

  useEffect(() => {
    const checkLogin = async () => {
      var values = {};
      values.token = state.token;
      values.username = state.username;
      const result = await SendToApi("admin/secureping", values);
      if (result) {
        if (result.json.status === 200) {
          setUserInfo((prevState) => ({
            ...prevState,
            loggedIn: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            connectionError: true,
          }));
        }
      }
    };
    if (userInfo.token) {
      checkLogin();
    }
  }, [userInfo.token, userInfo.username]);

  useEffect(() => {
    if (!userInfo.loggedIn) return;
    const getInfo = async () => {
      var values = {};
      values.token = state.token;
      values.username = state.username;
      const result = await SendToApi("users/info", values);
      if (result) {
        if (result.json.status === 200) {
          console.log(result.json);
          console.log(result.json.data["branding"].logo);
          setUserInfo((prevState) => ({
            ...prevState,
            colour1: result.json.data["branding"].colour1,
            colour2: result.json.data["branding"].colour2,
            logoNum: result.json.data["branding"].logo,
            logoUrl: result.json.data["branding"].logoUrl,
            logoName: result.json.data["branding"].logoName,
          }));
        }
      }
    };
    if (!userInfo.logo) {
      getInfo();
    }
  }, [userInfo.loggedIn]);

  useEffect(() => {
    // get the user token and username from the url
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (!params) {
    }
    if (params) {
      console.log(params);
      var token = params.get("token");
      if (!token) {
        if (process.env.NODE_ENV == "development") {
          token =
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InBldGVAY29kZXRlY2hzLmNvLnVrIiwiZXhwIjoxNjMzNDExNDMzLCJyb2xlIjoiIiwidXNlcl9pZCI6IjIwIn0.1tf-2yzXA7FcRhFips9zaVYyqaic9KSx1XgFLPRb7KE";
        } else {
          console.log("not in dev mode and no token created");
          return false;
        }
      }
      var decoded = jwt_decode(token);
      localStorage.setItem("token", token);
      console.log(decoded.username);
      console.log(decoded.user_id);
      // decode the token

      var username;
      var userId;
      username = decoded.username;
      userId = decoded.user_id;
      localStorage.setItem("username", username);
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      setUserInfo((prevState) => ({
        ...prevState,
        token: token,
        username: username,
        userId: userId,
      }));
    }
  }, []);

  function handleIsMember(member) {
    const roles = state.role.split(",");
    return roles.includes(member);
  }

  function handleLogout() {
    localStorage.clear();
    setState({
      ...state,
      loggedIn: false,
      token: "",
      username: "",
      error: "",
      failedAuth: true,
    });
  }

  function handleClearError() {
    setState({ ...state, error: "", success: "" });
  }
  function handleSetSelector(id, selected) {
    console.log("handleSelector");
    console.log(id);
    console.log(selected);
    console.log(selectorValue);
    setSelectorValue({ ...selectorValue, [id]: selected });
  }
  return (
    <GlobalStateContext.Provider
      value={{
        state: state,
        setState: setState,
        logout: handleLogout,
        clearError: handleClearError,
        isMember: handleIsMember,
        setSelector: handleSetSelector,
        selectors: selectorValue,
        userInfo: userInfo,
        setUserInfo: setUserInfo,
      }}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;

//https://auth0.com/blog/complete-guide-to-react-user-authentication/
