import { styled } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import { green } from "@mui/material/colors";
import { Avatar } from "@mui/material";

export default function SuccessAvatar(props) {
  const { success, value, icon } = props;

  const CustomAvatar = styled(Avatar)({
    color: success && "#ffffff",
    backgroundColor: success && green[500],
  });

  return (
    <CustomAvatar>
      {success && icon ? <DoneIcon /> : success && value ? value : ""}
    </CustomAvatar>
  );
}
