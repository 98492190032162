import { useContext, useEffect, useState } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";
import RealStateContext from "../realState/realStateContext";

import {
  Button,
  Card,
  CardContent,
  formControlClasses,
  Grid,
  Typography,
} from "@mui/material";
import StatusStepper from "./statusStepper";
import StatusProgress from "./statusProgress";
import SendToApi from "../../api/sendtoapi";
import { makeStyles } from "@mui/styles";
import { ContactsOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {},
  statusrow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  completedrow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
  },
  card: {
    marginBottom: "1em",
  },
});

export default function Status() {
  const classes = useStyles();
  const [statusRows, setStatusRows] = useState();
  const [completedRows, setCompletedRows] = useState();

  const rt = useContext(RealStateContext);

  useEffect(() => {
    const fetchStatusData = async () => {
      const values = {};
      values.total = "3";
      values.offset = 0;
      values.table = "jobs";
      values.fields = "*";
      values.filterName = "_user_id,status->>'state'";
      values.filterValue = localStorage.getItem("userId") + ",launched";
      values.include = ",_token";

      const result = await SendToApi("/tables/gettable", values);
      if (!result) {
        console.log("no result from API");
        //global.logout();
        return;
      }
      if (result.response.status !== 200) {
        console.log("no 200 result from API");
        //global.logout()
        return;
      }
      console.log("here are the results of the launched jobs");
      console.log(result);
      if (!result.json.data.rows) {
        console.log("no launched jobs found");
      }
      result.json.data.rows.forEach((e) => {
        var channel = {};
        channel.job_id = e._id;
        channel.job_token = e._token;
        rt.addWssChannel(channel);
        e.status = JSON.parse(e.status);
      });
      // loop through and alter each status
      setStatusRows(result.json.data.rows);
    };
    const fetchCompleteData = async () => {
      const values = {};
      values.total = "3";
      values.offset = 0;
      values.table = "jobs";
      values.fields = "*";
      values.filterName = "_user_id,status->>'state'";
      values.filterValue = localStorage.getItem("userId") + ",completed";
      values.include = ",_token";

      const result = await SendToApi("/tables/gettable", values);
      if (!result) {
        console.log("no result from get table");
        // global.logout();
        return;
      }
      if (result.response.status !== 200) {
        //global.logout()
        console.log("no 200 from get table");
        return;
      }
      console.log("here are the complete records");
      console.log(result);
      if (!result.json.data.rows) {
        console.log("no completed jobs found");
        return;
      }
      result.json.data.rows.forEach((e) => {
        var channel = {};
        channel.job_id = e._id;
        channel.job_token = e._token;
      });
      // loop through and alter each status
      setCompletedRows(result.json.data.rows);
    };
    fetchStatusData();
    fetchCompleteData();
  }, []);

  return (
    <div>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            LIVE STATUS
          </Typography>
          <Grid container spacing={3}>
            {statusRows &&
              statusRows.map((item, i) => (
                <Grid item xs={12} className={classes.statusrow} key={i}>
                  <h4>Job no. {item._id}</h4>
                  <StatusStepper
                    step={
                      rt.realTime[item._id]
                        ? rt.realTime[item._id].step
                        : item.status.step != 0
                        ? item.status.step
                        : 1
                    }
                  />
                  <StatusProgress
                    progress={
                      rt.realTime[item._id]
                        ? rt.realTime[item._id].progress
                        : item.status.progress
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            COMPLETED
          </Typography>
          <Grid container spacing={3}>
            {completedRows &&
              completedRows.map((item, i) => (
                <Grid item xs={12} className={classes.completedrow} key={i}>
                  <h4>Job no. {item._id}</h4>
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://wp.codetechs-ws.co.uk//wp-content//uploads//output.html"
                  >
                    View Report
                  </Button>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
