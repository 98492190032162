import { useContext, useState } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";
import { Button, Grid, Typography } from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { SendFormToApi } from "../../api/sendtoapi";
import SuccessAvatar from "../successavatar";
import ApiButton from "../apibutton";
import { Box } from "@mui/system";

export default function FileUpload(props) {
  const theme = useTheme();

  const { options, title, type, show, jobId } = props;
  const global = useContext(GlobalStateContext);

  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [finishedSubmitting, setFinishedSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);

  function onFileChange(e) {
    setSelectedFile(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  async function onFileUpload() {
    setSubmitting(true);
    setFinishedSubmitting(false);
    if (selectedFile) {
      // add token and username
      console.log(selectedFile);

      const token = global.userInfo.token;
      const username = global.userInfo.username;
      const formData = new FormData();

      console.log("token " + token);
      console.log("username " + username);
      console.log("options " + options);
      console.log("type " + type);

      formData.append("CTFile", selectedFile, selectedFile.name);
      formData.append("username", username);
      formData.append("jobid", jobId);
      formData.append("token", token);
      formData.append("options", options);
      formData.append("type", type);

      try {
        const result = await SendFormToApi("/files/upload", formData);

        if (result.response.status === 408) {
          console.log("************** error 408");
          global.setState({
            ...global.state,
            error: result.json.status_message,
          });
          global.setState({ ...global.state, error: "error : 124-478-234" });
          global.logout();
        }
        console.log("file id is " + result.json.data);
        const _id = result.json.data;
        console.log("setting success to true");
        setSuccess(true);
        if (type != "logo") {
          global.setSelector(type, _id); // id is 't1 t2' _id is the file ref
        }
        setSubmitting(false);
        setFinishedSubmitting(true);
        return true;
        //onComplete(); // cant rely on this - need to wait until state has updated before sending this
      } catch (err) {
        setSubmitting(false);
        setFinishedSubmitting(true);
        return false;
      }
    } else {
      console.log("no file selected");
    }
    setSubmitting(false);
    setFinishedSubmitting(true);
  }
  const fileData = () => {
    if (selectedFile) {
      if (type != "logo") {
        const fileDate = new Date(selectedFile.lastModified);
        return (
          <TableContainer sx={{ width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell align="right">File Type</TableCell>
                  <TableCell align="right">File Size</TableCell>
                  <TableCell align="right">Last Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{selectedFile.name}</TableCell>
                  <TableCell align="right">{selectedFile.type}</TableCell>
                  <TableCell align="right">{selectedFile.size}</TableCell>
                  <TableCell align="right">{fileDate.toDateString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
      if (type == "logo") {
        return (
          <Box sx={{}}>
            <img
              width="100px"
              height="100px"
              style={{
                marginTop: "2rem",
                objectFit: "contain",
                border: "solid thin purple",
              }}
              src={file}
            />

            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {selectedFile.name}
            </Typography>
          </Box>
        );
      }
    } else {
      return (
        <div>
          <br />
        </div>
      );
    }
  };

  return (
    <Grid container spacing={theme.spacing(1)} sx={{ paddingRight: "1rem" }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <div>
          <Button
            sx={{ marginRight: "1rem" }}
            disableRipple
            disabled={!show}
            variant="contained"
            component="label"
            color="primary"
            size="medium"
          >
            {title}
            <input type="file" hidden onChange={onFileChange} />
          </Button>

          <ApiButton
            disabled={!selectedFile || finishedSubmitting || !show}
            submitting={submitting ? "true" : "false"}
            size="medium"
            variant="contained"
            color="primary"
            onClick={onFileUpload}
          >
            Send to IOTA-ML
          </ApiButton>
        </div>
        <SuccessAvatar success={success != false} icon></SuccessAvatar>
      </Grid>
      <Grid item xs={12}>
        {fileData()}
      </Grid>
    </Grid>
  );
}
