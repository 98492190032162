import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Card, Divider, IconButton } from "@mui/material";
import globalStateContext from "../../globalstate/globalStateContext";
import { useContext, useEffect, useState } from "react";

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ColourPicker from "../../components/colourpicker/colourpicker";
import FileUpload from "./../../components/fileupload/fileupload";
import SendToApi from "../../api/sendtoapi";

export default function AccountDialog(props) {
  const { handleClose } = props;
  const global = useContext(globalStateContext);

  useEffect(() => {
    global.setUserInfo((prevState) => ({
      ...prevState,
      coloursDirty: false,
    }));

    return () => {
      console.log("leaving account dialog");
      const setInfo = async () => {
        var values = {};
        values["colour1"] = global.userInfo.colour1;
        values["colour2"] = global.userInfo.colour2;
        const result = await SendToApi("/users/setinfo", values);
        if (result) {
        }
      };
      // check if colours are dirty
      if (global.userInfo.coloursDirty) {
        setInfo();
      }
    };
  }, []);

  const UserCard = () => {
    return (
      <Card sx={{ maxWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            user details
          </Typography>
          <Typography variant="h5" component="div">
            {global.userInfo.username}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const AccountCard = () => {
    return (
      <Card sx={{}}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            branding details
          </Typography>
          <Typography variant="h5" component="div"></Typography>
          <FileUpload
            title="Logo upload"
            options="storeuserfile"
            type="logo"
            show="true"
            jobId="0"
          />
          {global.userInfo.logoUrl && (
            <Box sx={{}}>
              <img
                width="100px"
                height="100px"
                style={{
                  marginTop: "2rem",
                  objectFit: "contain",
                  border: "solid thin purple",
                }}
                src={global.userInfo.logoUrl}
              />

              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {global.userInfo.logoName}
              </Typography>
            </Box>
          )}
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Primary Colour
          </Typography>
          <ColourPicker id="colour1" />
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Secondary Colour
          </Typography>
          <ColourPicker id="colour2" />
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <DialogTitle>Account</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
            gap: "2rem",
          }}
        >
          <UserCard />
          <AccountCard />
        </Box>
      </DialogContent>

      <DialogActions>
        <IconButton aria-label="delete" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </>
  );
}
